import CoreApi from '../../api/core'

export default {
  methods: {
    async getOneQuestionCategories () {
      try {
        const response = await CoreApi().get('/quizCategory')
        return Object.entries(response.data)
          .filter(([id]) => id !== 'other')
          .map(([id, title]) => ({ id, title }))
      } catch (e) {
        return []
      }
    }
  }
}

<template>
  <section>
    <div class="title-text">
      {{ $t('quiz.image') }}
    </div>
    <ModuleFormPhotoBox
      :image="quiz.imageInfo"
      :disabled="disabled"
      @set-media="setImage"
      @remove-media="removeImage"
    />
    <OutlinedCheckbox
      v-model="quiz.enabled"
      id="quiz_enabled"
      :label="$t('quiz.enabled')"
      :disabled="disabled"
    />
    <!-- hide till CMS-541 is resolved -->
    <OutlinedCheckbox
      v-if="false"
      v-model="quiz.unpublished"
      id="quiz_setUnpublished"
      :label="$t('media.set_unpublished')"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'

export default {
  name: 'QuizFormMainTabRightColumn',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OutlinedCheckbox,
    ModuleFormPhotoBox
  },
  methods: {
    setImage (medias) {
      medias.forEach(media => {
        this.quiz.imageInfo = media
        this.quiz.image = media.id
      })
    },
    removeImage () {
      this.quiz.imageInfo = {}
      this.quiz.image = null
    }
  }
}
</script>

<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>

<template>
  <ModuleForm
    ref="quizForm"
    :module-name="$t('moduleForm.quizOneQuestion')"
    :module="quiz"
    :show-header-info="disabled"
    :tabs="tabs"
    @tab-change="(index) => tabIndex = index"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="quiz.id"
        :record-id="quiz.id"
        store-name="quiz"
        list-router-path="oneQuestion"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/oneQuestion')"
      />
    </template>
    <template #form-content>
      <div
        v-show="tabIndex === 0"
        class="form-content"
      >
        <div class="form-content__left">
          <QuizOneQuestionFormMainTabLeftColumn
            ref="mainLeftColumn"
            :quiz="quiz"
            :disabled="disabled"
          />
        </div>
        <div class="form-content__separator">
          <div class="form-content__separator__line"/>
        </div>
        <div class="form-content__right">
          <QuizOneQuestionFormMainTabRightColumn
            ref="mainRightColumn"
            :quiz="quiz"
            :disabled="disabled"
          />
        </div>
      </div>
      <QuizOneQuestionFormQuestionTab
        v-show="tabIndex === 1"
        ref="questionTab"
        :quiz="quiz"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import QuizOneQuestionModel from '@/model/QuizOneQuestionModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import QuizOneQuestionFormMainTabLeftColumn from '@/components/quiz/form/QuizOneQuestionFormMainTabLeftColumn'
import QuizOneQuestionFormMainTabRightColumn from '@/components/quiz/form/QuizOneQuestionFormMainTabRightColumn'
import QuizOneQuestionFormQuestionTab from '@/components/quiz/form/QuizOneQuestionFormQuestionTab'
import QuizAnswerModel from '@/model/QuizAnswerModel'
import QuizQuestionModel from '@/model/QuizQuestionModel'

export default {
  name: 'OneQuestionNewView',
  data () {
    return {
      quiz: this._.cloneDeep(QuizOneQuestionModel),
      tabs: [
        { name: this.$t('quiz.main_tab'), error: false },
        { name: this.$t('quiz.one_question_tab'), error: false }
      ],
      tabIndex: 0,
      categories: []
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    QuizOneQuestionFormQuestionTab,
    QuizOneQuestionFormMainTabRightColumn,
    QuizOneQuestionFormMainTabLeftColumn,
    ModuleForm,
    ModuleFormDeleteButton,
    ModuleFormButton
  },
  methods: {
    getQuiz () {
      this.$store.dispatch('quiz/fetchOne', this.$route.params.id)
        .then(() => {
          this.quiz = this.$store.getters['quiz/detail']
          if (this.quiz.questions.length === 0) {
            this.addQuestion()
          }
        })
        .catch(() => {
          NotifyService.setErrorMessage(this.$store.getters['quiz/error'])
        })
    },
    isFormInvalid () {
      const formRefs = [this.$refs.mainLeftColumn, this.$refs.mainRightColumn,
        this.$refs.questionTab]
      let invalid = false
      formRefs.forEach((ref, idx) => {
        if (ref?.$v) {
          const tabIndex = [0, 1].includes(idx) ? 0 : 1
          this.tabs[tabIndex].error = ref.$v.$invalid
          ref.$v.$touch()
          invalid = invalid || ref.$v.$invalid
        }
      })
      return invalid
    },
    prepareQuizQuestionRequest (question) {
      question.quiz = this.quiz.id
      question.answers.map(answer => {
        if (answer.imageInfo) {
          answer.image = answer.imageInfo.id
        }
      })
      return question
    },
    async saveQuestions () {
      for (const question of this.quiz.questions) {
        const action = question.id ? 'update' : 'create'
        await this.$store.dispatch(`quizQuestion/${action}`, this.prepareQuizQuestionRequest(question))
          .then(() => {
            if (this.$store.getters['quizQuestion/error'] !== null) {
              console.log(this.$store.getters['quizQuestion/error'])
              NotifyService.setErrorMessage(this.$store.getters['quizQuestion/error'])
            }
          })
      }
    },
    async save () {
      if (this.isFormInvalid()) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const action = this.quiz.id ? 'update' : 'create'
      const successNotify = this.quiz.id ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`quiz/${action}`, this.quiz)
        .then(async () => {
          if (this.$store.getters['quiz/error'] === null) {
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            this.quiz.id = this.$store.getters['quiz/detail'].id
            await this.saveQuestions()
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['quiz/error'])
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goToEdit () {
      const path = '/oneQuestion/' + this.quiz.id + '/edit'
      if (this.$router.currentRoute.path === path) {
        // reloads quiz with all its questions
        this.getQuiz()
      } else {
        this.$store.commit('quiz/setPreviousTabIndex', this.tabIndex)
        this.$router.push(path)
      }
    },
    addAnswer (question) {
      question.answers.push(this._.cloneDeep(QuizAnswerModel))
    },
    addQuestion () {
      const newQuestion = this._.cloneDeep(QuizQuestionModel)
      this.addAnswer(newQuestion)
      this.addAnswer(newQuestion)
      this.quiz.questions.push(newQuestion)
    }
  },
  created () {
    this.addQuestion()
  }
}
</script>

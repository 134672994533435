import { QUIZ_TYPE_ONE_QUESTION } from '../components/mixins/valueObject/QuizTypeMixin'
import { TYPE_TEXT_ANSWERS } from './ValueObject/QuizLayout'
import DateTimeService, { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  type: QUIZ_TYPE_ONE_QUESTION,
  title: '',
  description: '',
  enabled: false,
  image: null,
  imageInfo: null,
  setting: {
    layout: TYPE_TEXT_ANSWERS,
    questionsPerPage: 1,
    showImage: 1
  },
  questions: [],
  results: [],
  categories: [],
  publishedSince: dateTimeNow(),
  publishedUntil: DateTimeService.dateTimeMax,
  isPublished: false,
  unpublished: false
}
